import React from "react";
import "../../App.css";
import { withCookies } from "react-cookie";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import History from "../commons/History";
const styles = theme => ({
	theme: theme,
	root: {
		display: "flex"
	}
});

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			component: "",
			open: false,
			menu: []
		};
		this.handleItemClick = this.handleItemClick.bind();
	}
	componentDidMount() {
		if (this.props.cookies.cookies.policy) {
			var policy = JSON.parse(this.props.cookies.cookies.policy);
			var actions = [];
			actions.push({
				text: "Home",
				url: "/",
				icon: (
					<img
						src={"./icons/home.svg"}
						width={"24px"}
						height={"24px"}
						alt={"HOME"}
					/>
				)
			});
			var component = undefined;
			if(this.props.cookies.cookies.policyname === "admin")
			{
			policy.map(action => {
				switch (action) {
					case "authwalls":
						component = {
							text: "Authwalls",
							url: "/authwalls",
							icon: (
								<img
									src={"./icons/authwalls.svg"}
									width={"24px"}
									height={"24px"}
									alt={"AUTHWALL"}
								/>
							)
						};
						actions.push(component);
						if(this.props.cookies.cookies.policyname === "admin")
							{
							component = {
							text: "Bundle Upload",
							url: "/bundle_upload",
							icon: (
								<img
									src={"./icons/bundle_upload.svg"}
									width={"24px"}
									height={"24px"}
									alt={"M-AUTHWALL"}
								/>
							)
							};
						actions.push(component);
						}
						break;
					case "uploader":
						if(this.props.cookies.cookies.policyname === "admin")
						{
							component = {
								text: "Bundle Update",
								url: "/updater",
								icon: (
									<img
										src={"./icons/update.svg"}
										width={"24px"}
										height={"24px"}
										alt={"UPDATER"}
									/>
								)
							};							
						}else
						{
							if(this.props.cookies.cookies.policyname === "cvte_lite")
							{
								component = {
									text: "Bundle Update",
									url: "/updater",
									icon: (
										<img
											src={"./icons/update.svg"}
											width={"24px"}
											height={"24px"}
											alt={"UPDATER"}
										/>
									)
								};
							}
						}
						actions.push(component);						
						break;
					case "bundle_activator":
						if(this.props.cookies.cookies.policyname === "admin")
						{
						component = {
							text: "Bundle Activator",
							url: "/bundle_activator",
							icon: (
								<img
									src={"./icons/bundle_activator.svg"}
									width={"24px"}
									height={"24px"}
									alt={"ACTIVATOR"}
								/>
							)
							};
						}
						actions.push(component);
						break;
					case "csvbundle_uploader":
						component = {
							text: "Bulk Activator",
							url: "/csvbundle_uploader",
							icon: (
								<img
									src={"./icons/bundle_activator.svg"}
									width={"24px"}
									height={"24px"}
									alt={"CSVBUNDLE_UPLOADER"}
								/>
							)
						};
						actions.push(component);
						break;
					case "poscount":
							component = {
								text: "Summary",
								url: "/poscount",
								icon: (
									<img
										src={"./icons/summary.svg"}
										width={"24px"}
										height={"24px"}
										alt={"Summary"}
									/>
								)
							};
							actions.push(component);
						break;							
					case "blacklists":
						component = {
							text: "Blacklist",
							url: "/blacklists",
							icon: (
								<img
									src={"./icons/blacklist.svg"}
									width={"24px"}
									height={"24px"}
									alt={"AUTHWALL"}
								/>
							)
						};
						actions.push(component);
						break;
					case "reports":
						component = {
							text: "Reports",
							url: "/reports",
							icon: (
								<img
									src={"./icons/report.svg"}
									width={"24px"}
									height={"24px"}
									alt={"REPORTS"}
								/>
							)
						};
						actions.push(component);
						break;
					case "monthreports":
						component = {
							text: "Monthreports",
							url: "/monthreports",
							icon: (
								<img
									src={"./icons/report.svg"}
									width={"24px"}
									height={"24px"}
									alt={"MONTHREPORTS"}
								/>
							)
						};
						actions.push(component);
						break;
					case "skintvlaunchers":
						if(this.props.cookies.cookies.policyname === "admin")
							{
							component = {
								text: "Skins",
								url: "/skintvlaunchers",
								icon: (
									<img
										src={"./icons/palete.svg"}
										width={"24px"}
										height={"24px"}
										alt={"SKIN"}
									/>
								)
							};
							actions.push(component);
							}
							break;		
					case "firmwares":
						if(this.props.cookies.cookies.policyname === "admin")
							{
						component = {
							text: "Firmwares",
							url: "/firmwares",
							icon: (
								<img
									src={"./icons/firmware.svg"}
									width={"24px"}
									height={"24px"}
									alt={"FIRMWARE"}
								/>
							)
						};
						actions.push(component);
						}
						break;
					case "test_suite":
						component = {
							text: "Test Suite",
							url: "/test",
							icon: (
								<img
									src={"./icons/test.svg"}
									width={"24px"}
									height={"24px"}
									alt={"TEST_SUITE"}
								/>
							)
						};
						actions.push(component);
						break;
					case "featurelmslists":
						component = {
							text: "Feature List",
							url: "/featurelmslists",
							icon: (
								<img
									src={"./icons/features.svg"}
									width={"24px"}
									height={"24px"}
									alt={"FEATURE LIST"}
								/>
							)
						};
						actions.push(component);
						break;
					case "launcherlmslists":
						component = {
							text: "Launcher List",
							url: "/launcherlmslists",
							icon: (
								<img
									src={"./icons/services.svg"}
									width={"24px"}
									height={"24px"}
									alt={"LAUNCHERS LIST"}
								/>
							)
						};
						actions.push(component);
						break;
					case "users":
						if(this.props.cookies.cookies.policyname === "admin")
							{
						component = {
							text: "Users",
							url: "/users",
							icon: (
								<img
									src={"./icons/users.svg"}
									width={"24px"}
									height={"24px"}
									alt={"USERS"}
								/>
							)
						};
						actions.push(component);
					}
						break;
					case "policies":
						component = {
							text: "Policies",
							url: "/policies",
							icon: (
								<img
									src={"./icons/policy.svg"}
									width={"24px"}
									height={"24px"}
									alt={"POLICIES"}
								/>
							)
						};
						actions.push(component);
						break;
					case "actions":
						component = {
							text: "Actions",
							url: "/actions",
							icon: (
								<img
									src={"./icons/actions.svg"}
									width={"24px"}
									height={"24px"}
									alt={"ACTIONS"}
								/>
							)
						};
						actions.push(component);
						break;
					case "services":
						component = {
							text: "Services",
							url: "/services",
							icon: (
								<img
									src={"./icons/services.svg"}
									width={"24px"}
									height={"24px"}
									alt={"SERVICE"}
								/>
							)
						};
						actions.push(component);
						break;
					case "uiconfigs":
						if(this.props.cookies.cookies.policyname === "admin")
							{
						component = {
							text: "UIConfigs",
							url: "/uiconfigs",
							icon: (
								<img
									src={"./icons/uiconfigs.svg"}
									width={"24px"}
									height={"24px"}
									alt={"UICONFIG"}
								/>
							)
						};
						actions.push(component);
						}
						break;
					case "features":
						if(this.props.cookies.cookies.policyname === "admin")
							{
						component = {
							text: "Features",
							url: "/features",
							icon: (
								<img
									src={"./icons/features.svg"}
									width={"24px"}
									height={"24px"}
									alt={"FEATURE"}
								/>
							)
						};
						actions.push(component);
					}
						break;
					case "resources":
						component = {
							text: "Boards",
							url: "/boards",
							icon: (
								<img
									src={"./icons/board.svg"}
									width={"24px"}
									height={"24px"}
									alt={"BOARD"}
								/>
							)
						};
						actions.push(component);
						component = {
							text: "Brands",
							url: "/resources",
							icon: (
								<img
									src={"./icons/brands.svg"}
									width={"24px"}
									height={"24px"}
									alt={"BRANDS"}
								/>
							)
						};
						actions.push(component);
					if(this.props.cookies.cookies.policyname === "admin")
					{	
						component = {
							text: "Attributes",
							url: "/attributes",
							icon: (
								<img
									src={"./icons/attributes.svg"}
									width={"24px"}
									height={"24px"}
									alt={"Attributes"}
								/>
							)
						};
						actions.push(component);
					}	
						break;
					case "pos":
						component = {
							text: "PO",
							url: "/pos",
							icon: (
								<img
									src={"./icons/po.svg"}
									width={"24px"}
									height={"24px"}
									alt={"PO"}
								/>
							)
						};
						actions.push(component);
						break;
					case "logs":
						component = {
							text: "LOGS",
							url: "/logs",
							icon: (
								<img
									src={"./icons/logs.svg"}
									width={"24px"}
									height={"24px"}
									alt={"LOGS"}
								/>
							)
						};
						actions.push(component);
						break;
				}
			});
			}
			else
			{
				policy.map(action => {
					switch (action) {
						case "authwalls":
							component = {
								text: "Authwalls",
								url: "/authwalls",
								icon: (
									<img
										src={"./icons/authwalls.svg"}
										width={"24px"}
										height={"24px"}
										alt={"AUTHWALL"}
									/>
								)
							};
							actions.push(component);
							if(this.props.cookies.cookies.policyname === "cvte_lite")
								{
								component = {
								text: "Bundle Upload",
								url: "/bundle_upload",
								icon: (
									<img
										src={"./icons/bundle_upload.svg"}
										width={"24px"}
										height={"24px"}
										alt={"M-AUTHWALL"}
									/>
								)
								};
							actions.push(component);
							}
							break;
						case "csvbundle_uploader":
							component = {
								text: "Bulk Activator",
								url: "/csvbundle_uploader",
								icon: (
									<img
										src={"./icons/bundle_activator.svg"}
										width={"24px"}
										height={"24px"}
										alt={"CSVBUNDLE_UPLOADER"}
									/>
								)
							};
							actions.push(component);
							break;
						case "poscount":
								component = {
									text: "Summary",
									url: "/poscount",
									icon: (
										<img
											src={"./icons/summary.svg"}
											width={"24px"}
											height={"24px"}
											alt={"Summary"}
										/>
									)
								};
								actions.push(component);
							break;							
						case "blacklists":
							component = {
								text: "Blacklist",
								url: "/blacklists",
								icon: (
									<img
										src={"./icons/blacklist.svg"}
										width={"24px"}
										height={"24px"}
										alt={"AUTHWALL"}
									/>
								)
							};
							actions.push(component);
							break;
						case "reports":
							component = {
								text: "Reports",
								url: "/reports",
								icon: (
									<img
										src={"./icons/report.svg"}
										width={"24px"}
										height={"24px"}
										alt={"REPORTS"}
									/>
								)
							};
							actions.push(component);
							break;
						case "monthreports":
							component = {
								text: "Monthreports",
								url: "/monthreports",
								icon: (
									<img
										src={"./icons/report.svg"}
										width={"24px"}
										height={"24px"}
										alt={"MONTHREPORTS"}
									/>
								)
							};
							actions.push(component);
							break;
						case "skintvlaunchers":
							if(this.props.cookies.cookies.policyname === "admin")
								{
								component = {
									text: "Skins",
									url: "/skintvlaunchers",
									icon: (
										<img
											src={"./icons/palete.svg"}
											width={"24px"}
											height={"24px"}
											alt={"SKIN"}
										/>
									)
								};
								actions.push(component);
								}
								break;		
						case "featurelmslists":
							component = {
								text: "Feature List",
								url: "/featurelmslists",
								icon: (
									<img
										src={"./icons/features.svg"}
										width={"24px"}
										height={"24px"}
										alt={"FEATURE LIST"}
									/>
								)
							};
							actions.push(component);
							break;
						case "launcherlmslists":
							component = {
								text: "Launcher List",
								url: "/launcherlmslists",
								icon: (
									<img
										src={"./icons/services.svg"}
										width={"24px"}
										height={"24px"}
										alt={"LAUNCHERS LIST"}
									/>
								)
							};
							actions.push(component);
							break;
						case "resources":
							component = {
								text: "Boards",
								url: "/boards",
								icon: (
									<img
										src={"./icons/board.svg"}
										width={"24px"}
										height={"24px"}
										alt={"BOARD"}
									/>
								)
							};
							actions.push(component);
							component = {
								text: "Brands",
								url: "/resources",
								icon: (
									<img
										src={"./icons/brands.svg"}
										width={"24px"}
										height={"24px"}
										alt={"BRANDS"}
									/>
								)
							};
							actions.push(component);
						if(this.props.cookies.cookies.policyname === "admin")
						{	
							component = {
								text: "Attributes",
								url: "/attributes",
								icon: (
									<img
										src={"./icons/attributes.svg"}
										width={"24px"}
										height={"24px"}
										alt={"Attributes"}
									/>
								)
							};
							actions.push(component);
						}	
							break;
						case "pos":
							component = {
								text: "PO",
								url: "/pos",
								icon: (
									<img
										src={"./icons/po.svg"}
										width={"24px"}
										height={"24px"}
										alt={"PO"}
									/>
								)
							};
							actions.push(component);
							break;
						case "logs":
							component = {
								text: "LOGS",
								url: "/logs",
								icon: (
									<img
										src={"./icons/logs.svg"}
										width={"24px"}
										height={"24px"}
										alt={"LOGS"}
									/>
								)
							};
							actions.push(component);
							break;
					}
				});
				}
			actions.push({
				text: "About",
				url: "/about",
				icon: (
					<img
						src={"./icons/about.svg"}
						width={"24px"}
						height={"24px"}
						alt={"ABOUT"}
					/>
				)
			});
			this.setState({ menu: actions });
		}
	}
	
	handleItemClick = url => event => {
		this.setState({ url: url });
		//TODO: Route to url
		console.log(url);
		if (this.props.cookies.cookies.username == undefined) {
			window.location.reload();
		} else {
			History.push(url);
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<List>
					{this.state.menu.map(item => (
						<ListItem
							button
							key={item.text}
							onClick={this.handleItemClick(item.url)}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText>{item.text}</ListItemText>
						</ListItem>
					))}
				</List>
			</div>
		);
	}
}
export default withStyles(styles)(withCookies(Sidebar));
