import superagent from 'superagent';

const URL = '/lm/v1/csvbundle_uploader';

export const csvbundleUploader = (auth_body, callback) => {
    superagent.post(URL)
        .send(auth_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
            console.log(response)
        });
};

export const get = (query, callback) => {
    superagent.post(URL + '/vendors')
    .query(query)
    .end((err, response) => {
        if (err) {
            callback([]);
            console.log('Request failed')
        } else {
            if (response.status === 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
        }
        console.log(response);
    });
};


export const boards = (brand, callback) => {
    console.log("ADED=============>>>")
    superagent.post(URL + '/boards')
        .send({ brand: brand })
        .end((err, response) => {
            console.log(response)
            if (err) {
                callback(undefined);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(undefined)
                }
            }
        });
};