import React from 'react';
import '../../../App.css';
import "../../../Shineout.css";
import MaterialTable from 'material-table';
import { filter, delet } from "../network/Authwalls";
import { routePermissions } from "../../auth/network/Login";
import { withCookies } from "react-cookie";
import { Message } from 'shineout'
import history from '../../commons/History';
import { testOne } from "../network/TestSuite";

const service = 'authwalls';
const path = '';
class TVList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                emac: { title: 'EMAC', field: 'emac' },
                wmac: { title: 'WMAC', field: 'wmac' },
                model: {
                    title: 'Model', field: 'model', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                brand: { title: 'Brand', field: 'brand' },
                mboard: {
                    title: 'MBoard', field: 'mboard', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                panel: {
                    title: 'Panel', field: 'panel', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                lversion: {
                    title: 'L-Version', field: 'lversion', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                serialno: {
                    title: 'Serial No', field: 'serialno', cellStyle: {
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                attributes: {
                    title: 'Attributes', field: 'attributes', cellStyle: {
                        maxWidth: 80,
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                features: {
                    title: 'Features', field: 'features', cellStyle: {
                        overflow: 'hidden',
                        maxWidth: 60,
                        'text-overflow': 'ellipsis',
                        'white-space': 'nowrap',
                    }
                },
                cotaversion: { title: 'COTA', field: 'cotaversion' },
                fotaversion: { title: 'FOTA', field: 'fotaversion' },
                factory: { title: 'FACTORY', field: 'factory' },
                vendor: { title: 'VENDOR', field: 'vendor' },

                description: {
                    title: 'Description', field: 'description', cellStyle: {
                        maxWidth: 60,
                        overflow: 'hidden',
                        'text-overflow': 'ellipsis',
                    }
                },
                accept_version: { title: 'Accept-version', field: 'accept_version' },
                lastacessIP: { title: 'Last IP', field: 'lastacessIP' },
                state: { title: 'State', field: 'state_text' },
            },
            columns: [],
            data: [],
            query: { page: 0, pageSize: 5, orderDirection: '' },
            pageSize: 100,
            isError: false,
            policies: JSON.parse(this.props.cookies.cookies.policy),
            isLoading: true,
            POST: false,
            GET: false,
            PUT: false,
            DELETE: false,
            POST_DATA: [],
            GET_DATA: [],
            PUT_DATA: [],
            DELETE_DATA: [],
        };
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        console.log({ service: service, path: path })
        routePermissions(this.props, { service: service, path: path }, result => {
            if (result === null) {
                this.setState({ isError: true })
            } else {
                result.map(method => {
                    this.setState({ [method.name]: true })
                    this.setState({ [method.name + '_DATA']: method.resources })
                    return null;
                })
                if (this.state.GET) {
                    var visible_fields = this.state.GET_DATA.find(e => e.key === 'visible_fields');
                    console.log(visible_fields)
                    if (!visible_fields) {
                        this.setState({ columns: null });
                    } else {
                        var fields = [];
                        if (visible_fields.value.indexOf('*') > -1) {
                            Object.keys(this.state.fields).map(k => fields.push(this.state.fields[k]))
                        } else {
                            visible_fields.value.map(e => fields.push(this.state.fields[e]))
                        }
                        console.log(fields)
                        this.setState({ columns: fields });
                    }
                } else {
                    this.setState({ columns: null });
                }

                this.setState({ isLoading: false })
                this.tableRef.current && this.tableRef.current.onQueryChange()
                if (this.state.GET) {
                    filter(this.state.query, (data) => {
                        this.setState({ data: data })
                        this.setState({ isLoading: false })
                    });
                }
            }
        })
    }

    render() {
        return (
            <div >
                <MaterialTable
                    title={'Device List'}
                    columns={this.state.columns}
                    tableRef={this.tableRef}
                    data={this.state.GET ? query =>
                        new Promise((resolve, reject) => {
                            query.brand = this.state.policies.resources;
                            this.setState({ pageSize: query.pageSize })
                            this.setState({ query: query })
                            this.setState({ isLoading: true })
                            //console.log(query)
                            filter(query, (data) => {
                                console.log(data)
                                this.setState({ isLoading: false })
                                data.result.map(row => {
                                    if (row.state == 0) {
                                        row.state_text = 'Deactivated'
                                    } else if (row.state == 1) {
                                        row.state_text = 'Activated'
                                    } else {
                                        row.state_text = 'Blocked'
                                    }
                                    return null
                                })
                                this.setState({ data: data.result })
                                resolve({ data: data.result, page: query.page !== 'NaN' ? query.page : 0, totalCount: data.count });
                            });
                        }) : []
                    }
                    isLoading={this.state.isLoading}
                    options={{
                        loadingType: 'overlay',
                        padding: 'dense',
                        filtering: true,
                        grouping: true,
                        addRowPosition: 'first',
                        exportAllData: true,
                        exportButton: true,
                        exportDelimiter: ',',
                        exportFileName: 'TV_Lists',
                        pageSize:200,
                        //pageSize: this.state.pageSize,
                        //pageSizeOptions: [50, 100, 200, 300, 500, 1000, 2000],
                        actionsColumnIndex: 100,
                        rowStyle: rowData => ({
                            backgroundColor: (rowData.tableData.id % 2 == 0) ?
                                '#F1F3F4' : '#FFF' 
                        }),
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) => {
                        console.log(rowData);
                        history.replace('/device_details', { data: rowData });
                    }}
                    actions={this.state.POST ? [
                        {
                            icon: 'add',
                            tooltip: 'Add Device',
                            isFreeAction: true,
                            onClick: () => history.replace('/device_details',
                                { data: undefined }),
                        }, {
                            icon: 'offline_bolt',
                            tooltip: 'Check OTA',
                            onClick: (event, rowData) => {
                                testOne(rowData, result => {
                                    if (result) {
                                        Message.success('Test Successful', 3, { position: 'bottom-right' });
                                    }
                                    else {
                                        Message.error('Test Failed', 3, { position: 'bottom-right' });
                                    }
                                })
                            },
                        }
                    ] : []}
                    editable={{
                        onRowDelete: this.state.DELETE ? oldData =>
                            new Promise(resolve => {
                                delet(oldData.emac, result => {
                                    if (result)
                                        filter(this.state.query, (data) => {
                                            this.setState({ data: data.result })
                                            Message.success('Device deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed to delete device. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }) : undefined,
                    }}
                />
            </div>
        );
    }
}

export default withCookies(TVList);
