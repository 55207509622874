import React from 'react';
import { withCookies } from "react-cookie";
import MaterialTable from 'material-table';
import { getAnalytics } from '../network/Authwalls'
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";


const styles = theme => ({
    theme: theme,
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        },
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
});



class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            open: false,
            data: [],
            showLoading: true,
            // data: { brand: [], model: [], mboard: [] },
            policies: this.props.cookies.cookies.policy != undefined ? JSON.parse(this.props.cookies.cookies.policy) : null,
        };
        console.log(this.props.cookies);
    }
    componentDidMount() {
        
        getAnalytics(data => {
            console.log(data)
            if (data != null) {
                var active = 0;
                var deactive = 0;
                var total = 0;
                data.map(row => {
                    active += row.activated
                    deactive += row.deactivated
                    total += row.total
                })
                data.push({ brand: 'Total', activated: active, deactivated: deactive, total: total })
                this.setState({ data: data, showLoading: false })
                console.log(this.state.data)
            }
        })
        
    }
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <MaterialTable
                    title={'LMS Analysis'}
                    isLoading={this.state.showLoading}
                    options={
                        {
                            exportAllData: true,
                            exportButton: true,
                            exportDelimiter: ',',
                            exportFileName: 'LMS_Analysis',
                            pageSize: 20,
						    pageSizeOptions: [20, 50, 100, 150, 500],
                        }
                    }
                    detailPanel={rowData => {
                        if (rowData.brand != 'Total')
                            var data = []
                        rowData.boards.map(v => {
                            var temp = ''
                            if (v.features && Array.isArray(v.features)) {
                                v.features.map(i => {
                                    temp += " " + i
                                })
                                v.features = temp
                            }
                            data.push(v)
                        })
                        return (
                            <MaterialTable
                                style={{ marginLeft: 16, marginBottom: 8 }}
                                title={''}
                                options={
                                    {
                                        toolbar: false,
                                        search: false,
                                        paging: false,
                                        padding: "dense",
                                    }
                                }
                                columns={[
                                    { title: 'M-Board', field: 'board' },
                                    { title: 'Features', field: 'features' },
                                    //{ title: 'Attributes', field: 'attributes' },
                                    { title: 'Activated', field: 'activated' },
                                    { title: 'Deactivated', field: 'deactivated' },
                                    { title: 'Total', field: 'total' },
                                ]}
                                data={data}
                            />
                        )
                    }}
                    onRowClick={(event, rowData, toggleDetailPanel) => rowData.brand != 'Total' ? toggleDetailPanel() : ''}
                    columns={[
                        { title: 'Brand', field: 'brand' },
                        { title: 'Activated', field: 'activated' },
                        { title: 'Deactivated', field: 'deactivated' },
                        { title: 'Total', field: 'total' },
                    ]}
                    data={this.state.data}

                />
            </React.Fragment>

        );
    }
}
export default withStyles(styles)(withCookies(Home));