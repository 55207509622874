import superagent from 'superagent';

const URL = '/lm/v1/featurelmslists';

export const create = (auth_body, callback) => {
    superagent.post(URL)
        .send(auth_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const bundleUpload = (auth_body, callback) => {
    superagent.post(URL + '/create_bundle')
        .send(auth_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const bundleUpdate = (auth_body, callback) => {
    superagent.post(URL + '/update_bundle')
        .send(auth_body)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const emacValidation = (brand, emacs, callback) => {
    superagent.post(URL + '/validate_emac')
        .send({ brand: brand, emacs: emacs })
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};


export const getAnalytics = (callback) => {
    superagent.get(URL + '/analytics_list')
        .end((err, response) => {
            if (err) {
                callback(null);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else if (response.status === 401) {
                    callback(null)
                } else {
                    callback(null)
                }
            }
        });
};


export const get = (query, callback) => {
    superagent.get(URL)
        .query(query)
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('No data available')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
        });
};

export const filter = (query, callback) => {
    superagent.get(URL + '/filter')
        .query(query)
        .end((err, response) => {
            if (err) {
                callback([]);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body);
                } else {
                    callback([]);
                    console.log('No data available')
                }
            }
        });
};

export const update = (oldValue, newValue, callback) => {
    newValue.old_emac = oldValue.emac;
    superagent.put(URL)
        .send(newValue)
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                }
            }
        });
};

export const delet = (emac, callback) => {
    superagent.delete(URL)
        .send({ emac: emac })
        .end((err, response) => {
            if (err) {
                callback(false);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(true)
                } else {
                    callback(false)
                }
            }
        });
};

export const getAllowedLicenseCount = (callback) => {
    superagent.get(URL + '/total_lic')
        .end((err, response) => {
            if (err) {
                callback(null);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(null)
                }
            }
        });
};

export const getUsedLicenseCount = (callback) => {
    superagent.get(URL + '/used_lic')
        .end((err, response) => {
            if (err) {
                callback(null);
                console.log('Request failed')
            } else {
                if (response.status === 200) {
                    callback(response.body)
                } else {
                    callback(null)
                }
            }
        });
};

