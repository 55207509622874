import React from 'react';
import { withCookies } from "react-cookie";
import withStyles from "@material-ui/core/styles/withStyles";
import { version } from '../../../../package.json'
const styles = theme => ({
    theme: theme,
    root: {
        display: 'flex',
    },
});

class About extends React.Component {
    /*constructor(props) {
        super(props);
    }
    */
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment className={classes.root}>
                <h1>Cloudwalker Licence Management System</h1>
                <h2>All rights reserved @Cloudwalker Streaming Technologies PVT. LTD.</h2>
                <h3>Client Version: v{version}  |   Server Version: v{this.props.cookies.cookies.version}</h3>
            </React.Fragment>

        );
    }
}
export default withStyles(styles)(withCookies(About));